<template>
  <div>
    <main class="router-view-container">
      <div class="flex items-center space-x-4 mb-5">
        <vs-button @click="$router.go(-1)" border size="large">
          <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
        </vs-button>

        <h1 class="page_title">{{ $route.meta.title }}</h1>
      </div>

      <ValidationObserver v-if="is_API_loaded" v-slot="{ invalid }">
        <div class="space-y-10">
          <div class="space-y-10">
            <section class="block">
              <div class="leftContent">
                <h3 class="h3">免費網域</h3>
                <p>我們提拱子網域供您使用，此為永久免費，您可以更換新的子域名或使用您的網域。</p>
                <!-- <p>
                當商店註冊後，系統會以你的 Email 開頭作為平台子域名。例如，Email 帳號是 example@gmail.com，則商店網址是
                example.shop.com。 平台子域名是永久免費，也可以更換新的平台子域名。
              </p> -->
              </div>

              <div class="box">
                <div>
                  <h4 class="h4 mb-1">預設網域 *</h4>
                  <div class="flex items-center space-x-1">
                    <span>https://</span>
                    <ValidationProvider tag="div" class="relative" name="預設網域" rules="required|max:20|alpha_dash" v-slot="{ errors }">
                      <vs-input v-model="wlink1" />
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                    <span>.{{ server_domain }}</span>
                  </div>
                </div>
                <p class="text-gray-400">※ 必須為小寫英數字組合</p>
              </div>
            </section>

            <hr />

            <section class="block">
              <div class="leftContent">
                <h3 class="h3">自訂網域</h3>
                <p>使用您自己擁有的網域。</p>
              </div>

              <div class="box">
                <div class="w-full">
                  <h4 class="h4 mb-1">提交您的域名</h4>
                    <ValidationProvider name="自訂網域" rules="max:150" v-slot="{ errors }" >
                      <vs-input v-model="wlink2" />
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                </div>
                <div>
                  <!-- <p class="text-gray-400">※ 必須為小寫英數字組合</p> -->
                  <p class="text-gray-400">※ 請自行購買網域，提交前請先設定 DNS 的 A紀錄 指向IP位置 {{ server_ip }}。</p>
                  <p class="text-gray-400">
                    ※ 如果你要使用 www 開頭作為主要域名，請記得 your-domain.com 和 www.your-domain.com 指向同一個 IP 位置。
                  </p>
                  <p class="text-gray-400">※ 提交後大約24小時內變更您的商店網址。</p>
                </div>
              </div>
            </section>

            <hr />
            <div class="flex justify-end mx-4">
              <vs-button size="large" @click="saveData(invalid)" :disabled="invalid" ref="saveData"
                ><span class="font-medium">儲存</span></vs-button
              >
            </div>
          </div>
        </div>
      </ValidationObserver>

      <!-- 骨架屏 -->
      <div class="space-y-4" v-else>
        <section v-for="(item, index) in 2" :key="index" class="block">
          <div class="space-y-4 w-full sm:w-1/3">
            <skeleton class="mt-4" width="100px" />
            <skeleton width="200px" height="10px" />
            <skeleton width="200px" height="10px" />
            <skeleton width="200px" height="10px" />
          </div>
          <div class="box">
            <skeleton v-for="(item, index) in 6" :key="index" width="200px" height="10px" />
          </div>
        </section>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'domains',
  data() {
    return {
      is_API_loaded: false,
      wlink1: '',
      wlink2: '',
      server_domain: process.env.VUE_APP_SERVERDOMAIN,
      server_ip: process.env.VUE_APP_SERVERIP,
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.$axios({
        url: 'front/set/store/getDomainData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          let data = res.data.Data

          this.wlink1 = data.wlink1
          this.wlink2 = data.wlink2

          this.is_API_loaded = true
        } else {
          console.error(res.data.Message)
        }
      })
    },
    saveData(invalid) {
      // 如果驗證未通過，return
      if (invalid) return

      const loading = this.$vs.loading({
        target: this.$refs.saveData,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      let data = {
        lang: 0,
        wlink1: this.wlink1,
        wlink2: this.wlink2,
      }

      this.$axios({
        url: 'front/set/store/uDomainData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: data,
      }).then((res) => {
        if (res.data.Success) {
          this.getWebData()
          this.loadData()
          this.$myVS.openNoti(res.data.Message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
        loading.close()
      })
    },
    // 1.3.1 讀取網站參數
    getWebData() {
      this.$axios({
        url: 'front/overview/getWebParam.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          // 儲存網站參數
          this.$store.commit('getWebData/UPDATE_COOKIE', res.data.Data)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.block {
  @apply flex flex-col sm:flex-row sm:space-x-10 space-y-4 sm:space-y-0;
}

.leftContent {
  @apply w-full sm:w-1/3 space-y-4 flex-shrink-0 sm:mt-4;
}

.box {
  @apply flex-grow h-fit bg-white p-4 rounded-xl space-y-4;
}
</style>
